// strings should be unique across reducers so namespace them with the reducer name

export const NOTIFICATION_STATUS_FALSE = "NOTIFICATION_STATUS_FALSE";

////////////////////// Auth User ///////////////////////////////
export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const LOGIN_PHONE_START = "LOGIN_PHONE_START";
export const LOGIN_PHONE_SUCCESS = "LOGIN_PHONE_SUCCESS";
export const LOGIN_PHONE_FAIL = "LOGIN_PHONE_FAIL";

export const LOGOUT_START = "LOGOUT_START";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAIL = "LOGOUT_FAIL";

export const RESET_START = "RESET_START";
export const RESET_SUCCESS = "RESET_SUCCESS";
export const RESET_FAIL = "RESET_FAIL";

export const SET_VCODE = "SET_VCODE";

////////////////////// App Users ///////////////////////////////
export const GET_USERS_START = "GET_USERS_START";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAIL = "GET_USERS_FAIL";

export const GET_MORE_USERS_START = "GET_MORE_USERS_START";
export const GET_MORE_USERS_SUCCESS = "GET_MORE_USERS_SUCCESS";
export const GET_MORE_USERS_FAIL = "GET_MORE_USERS_FAIL";

export const CREATE_USERS_START = "CREATE_USERS_START";
export const CREATE_USERS_SUCCESS = "CREATE_USERS_SUCCESS";
export const CREATE_USERS_FAIL = "CREATE_USERS_FAIL";

export const UPDATE_USERS_START = "UPDATE_USERS_START";
export const UPDATE_USERS_SUCCESS = "UPDATE_USERS_SUCCESS";
export const UPDATE_USERS_FAIL = "UPDATE_USERS_FAIL";

export const DELETE_USERS_START = "DELETE_USERS_START";
export const DELETE_USERS_SUCCESS = "DELETE_USERS_SUCCESS";
export const DELETE_USERS_FAIL = "DELETE_USERS_FAIL";

export const GET_USER_PROFILE_START = "GET_USER_PROFILE_START";
export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS";
export const GET_USER_PROFILE_FAIL = "GET_USER_PROFILE_FAIL";

export const GET_USER_BY_EMAIL_START = "GET_USER_BY_EMAIL_START";
export const GET_USER_BY_EMAIL_SUCCESS = "GET_USER_BY_EMAIL_SUCCESS";
export const GET_USER_BY_EMAIL_FAIL = "GET_USER_BY_EMAIL_FAIL";

export const CREATE_USERS_NOTIFICATION_START = "CREATE_USERS_NOTIFICATION_START";
export const CREATE_USERS_NOTIFICATION_SUCCESS = "CREATE_USERS_NOTIFICATION_SUCCESS";
export const CREATE_USERS_NOTIFICATION_FAIL = "CREATE_USERS_NOTIFICATION_FAIL";

export const UPDATE_USERS_NOTIFICATION_START = "UPDATE_USERS_NOTIFICATION_START";
export const UPDATE_USERS_NOTIFICATION_SUCCESS = "UPDATE_USERS_NOTIFICATION_SUCCESS";
export const UPDATE_USERS_NOTIFICATION_FAIL = "UPDATE_USERS_NOTIFICATION_FAIL";

////////////////////// Company Users ///////////////////////////////
export const GET_COMPANY_USERS_START = "GET_COMPANY_USERS_START";
export const GET_COMPANY_USERS_SUCCESS = "GET_COMPANY_USERS_SUCCESS";
export const GET_COMPANY_USERS_FAIL = "GET_COMPANY_USERS_FAIL";

export const GET_COMPANY_USERS_BY_COMPANIES_START = "GET_COMPANY_USERS_BY_COMPANIES_START";
export const GET_COMPANY_USERS_BY_COMPANIES_SUCCESS = "GET_COMPANY_USERS_BY_COMPANIES_SUCCESS";
export const GET_COMPANY_USERS_BY_COMPANIES_FAIL = "GET_COMPANY_USERS_BY_COMPANIES_FAIL";

export const GET_MORE_COMPANY_USERS_START = "GET_MORE_COMPANY_USERS_START";
export const GET_MORE_COMPANY_USERS_SUCCESS = "GET_MORE_COMPANY_USERS_SUCCESS";
export const GET_MORE_COMPANY_USERS_FAIL = "GET_MORE_COMPANY_USERS_FAIL";

export const CREATE_COMPANY_USERS_START = "CREATE_COMPANY_USERS_START";
export const CREATE_COMPANY_USERS_SUCCESS = "CREATE_COMPANY_USERS_SUCCESS";
export const CREATE_COMPANY_USERS_FAIL = "CREATE_COMPANY_USERS_FAIL";

export const UPDATE_COMPANY_USERS_START = "UPDATE_COMPANY_USERS_START";
export const UPDATE_COMPANY_USERS_SUCCESS = "UPDATE_COMPANY_USERS_SUCCESS";
export const UPDATE_COMPANY_USERS_FAIL = "UPDATE_COMPANY_USERS_FAIL";

export const DELETE_COMPANY_USERS_START = "DELETE_COMPANY_USERS_START";
export const DELETE_COMPANY_USERS_SUCCESS = "DELETE_COMPANY_USERS_SUCCESS";
export const DELETE_COMPANY_USERS_FAIL = "DELETE_COMPANY_USERS_FAIL";

export const GET_COMPANY_USER_BY_EMAIL_START = "GET_COMPANY_USER_BY_EMAIL_START";
export const GET_COMPANY_USER_BY_EMAIL_SUCCESS = "GET_COMPANY_USER_BY_EMAIL_SUCCESS";
export const GET_COMPANY_USER_BY_EMAIL_FAIL = "GET_COMPANY_USER_BY_EMAIL_FAIL";

export const GET_COMPANY_USER_PROFILE_START = "GET_COMPANY_USER_PROFILE_START";
export const GET_COMPANY_USER_PROFILE_SUCCESS = "GET_COMPANY_USER_PROFILE_SUCCESS";
export const GET_COMPANY_USER_PROFILE_FAIL = "GET_COMPANY_USER_PROFILE_FAIL";

////////////////////// Special Users ///////////////////////////////
export const GET_SPECIAL_USERS_START = "GET_SPECIAL_USERS_START";
export const GET_SPECIAL_USERS_MORE_START = "GET_SPECIAL_USERS_MORE_START";
export const GET_SPECIAL_USERS_SUCCESS = "GET_SPECIAL_USERS_SUCCESS";
export const GET_SPECIAL_USERS_WITH_ID_SUCCESS = "GET_SPECIAL_USERS_WITH_ID_SUCCESS";
export const GET_SPECIAL_USERS_WITHOUT_ID_SUCCESS = "GET_SPECIAL_USERS_WITHOUT_ID_SUCCESS";
export const SEARCH_SPECIAL_USERS_SUCCESS = "SEARCH_SPECIAL_USERS_SUCCESS";
export const GET_SPECIAL_USERS_FAIL = "GET_SPECIAL_USERS_FAIL";

export const DELETE_SPECIAL_USERS_START = "DELETE_SPECIAL_USERS_START";
export const DELETE_SPECIAL_USERS_SUCCESS = "DELETE_SPECIAL_USERS_SUCCESS";
export const DELETE_SPECIAL_USERS_FAIL = "DELETE_SPECIAL_USERS_FAIL";

export const DELETE_SPECIAL_IDS_START = "DELETE_SPECIAL_IDS_START";
export const DELETE_SPECIAL_IDS_SUCCESS = "DELETE_SPECIAL_IDS_SUCCESS";
export const DELETE_SPECIAL_IDS_FAIL = "DELETE_SPECIAL_IDS_FAIL";

export const CREATE_SPECIAL_USERS_START = "CREATE_SPECIAL_USERS_START";
export const CREATE_SPECIAL_USERS_SUCCESS = "CREATE_SPECIAL_USERS_SUCCESS";
export const CREATE_SPECIAL_USERS_FAIL = "CREATE_SPECIAL_USERS_FAIL";

export const UPDATE_SPECIAL_USERS_START = "UPDATE_SPECIAL_USERS_START";
export const UPDATE_SPECIAL_USERS_SUCCESS = "UPDATE_SPECIAL_USERS_SUCCESS";
export const UPDATE_SPECIAL_USERS_FAIL = "UPDATE_SPECIAL_USERS_FAIL";

////////////////////// Company ///////////////////////////////
export const GET_COMPANY_START = "GET_COMPANY_START";
export const GET_COMPANY_SUCCESS = "GET_COMPANY_SUCCESS";
export const GET_COMPANY_FAIL = "GET_COMPANY_FAIL";

export const GET_MORE_COMPANY_START = "GET_MORE_COMPANY_START";
export const GET_MORE_COMPANY_SUCCESS = "GET_MORE_COMPANY_SUCCESS";
export const GET_MORE_COMPANY_FAIL = "GET_MORE_COMPANY_FAIL";

export const GET_COMPANY_BY_ID_START = "GET_COMPANY_BY_ID_START";
export const GET_COMPANY_BY_ID_SUCCESS = "GET_COMPANY_BY_ID_SUCCESS";
export const GET_COMPANY_BY_ID_FAIL = "GET_COMPANY_BY_ID_FAIL";

export const CREATE_COMPANY_START = "CREATE_COMPANY_START";
export const CREATE_COMPANY_SUCCESS = "CREATE_COMPANY_SUCCESS";
export const CREATE_COMPANY_FAIL = "CREATE_COMPANY_FAIL";

export const UPDATE_COMPANY_START = "UPDATE_COMPANY_START";
export const UPDATE_COMPANY_SUCCESS = "UPDATE_COMPANY_SUCCESS";
export const UPDATE_COMPANY_FAIL = "UPDATE_COMPANY_FAIL";

export const DELETE_COMPANY_START = "DELETE_COMPANY_START";
export const DELETE_COMPANY_SUCCESS = "DELETE_COMPANY_SUCCESS";
export const DELETE_COMPANY_FAIL = "DELETE_COMPANY_FAIL";

export const GET_COMPANY_CATEGORY_START = "GET_COMPANY_CATEGORY_START";
export const GET_COMPANY_CATEGORY_SUCCESS = "GET_COMPANY_CATEGORY_SUCCESS";
export const GET_COMPANY_CATEGORY_FAIL = "GET_COMPANY_CATEGORY_FAIL";

export const CREATE_COMPANY_CATEGORY_START = "CREATE_COMPANY_CATEGORY_START";
export const CREATE_COMPANY_CATEGORY_SUCCESS = "CREATE_COMPANY_CATEGORY_SUCCESS";
export const CREATE_COMPANY_CATEGORY_FAIL = "CREATE_COMPANY_CATEGORY_FAIL";

export const UPDATE_COMPANY_CATEGORY_START = "UPDATE_COMPANY_CATEGORY_START";
export const UPDATE_COMPANY_CATEGORY_SUCCESS = "UPDATE_COMPANY_CATEGORY_SUCCESS";
export const UPDATE_COMPANY_CATEGORY_FAIL = "UPDATE_COMPANY_CATEGORY_FAIL";

export const DELETE_COMPANY_CATEGORY_START = "DELETE_COMPANY_CATEGORY_START";
export const DELETE_COMPANY_CATEGORY_SUCCESS = "DELETE_COMPANY_CATEGORY_SUCCESS";
export const DELETE_COMPANY_CATEGORY_FAIL = "DELETE_COMPANY_CATEGORY_FAIL";

export const CHANGE_COMPANY_STATUS_START = "CHANGE_COMPANY_STATUS_START";
export const CHANGE_COMPANY_STATUS_SUCCESS = "CHANGE_COMPANY_STATUS_SUCCESS";
export const CHANGE_COMPANY_STATUS_FAIL = "UPDATE_COMPANY_FAIL";

////////////////////// Special company ///////////////////////////////
export const GET_SPECIAL_COMPANY_START = "GET_SPECIAL_COMPANY_START";
export const GET_SPECIAL_COMPANY_SUCCESS = "GET_SPECIAL_COMPANY_SUCCESS";
export const GET_SPECIAL_COMPANY_BYGROUP_SUCCESS = "GET_SPECIAL_COMPANY_BYGROUP_SUCCESS";
export const GET_SPECIAL_COMPANY_FAIL = "GET_SPECIAL_COMPANY_FAIL";

export const GET_SPECIAL_COMPANY_BY_ID_START = "GET_SPECIAL_COMPANY_BY_ID_START";
export const GET_SPECIAL_COMPANY_BY_ID_SUCCESS = "GET_SPECIAL_COMPANY_BY_ID_SUCCESS";
export const GET_SPECIAL_COMPANY_BY_ID_FAIL = "GET_SPECIAL_COMPANY_BY_ID_FAIL";

export const UPDATE_SPECIAL_COMPANY_START = "UPDATE_SPECIAL_COMPANY_START";
export const UPDATE_SPECIAL_COMPANY_SUCCESS = "UPDATE_SPECIAL_COMPANY_SUCCESS";
export const UPDATE_SPECIAL_COMPANY_FAIL = "UPDATE_SPECIAL_COMPANY_FAIL";

export const CREATE_SPECIAL_COMPANY_START = "CREATE_SPECIAL_COMPANY_START";
export const CREATE_SPECIAL_COMPANY_SUCCESS = "CREATE_SPECIAL_COMPANY_SUCCESS";
export const CREATE_SPECIAL_COMPANY_FAIL = "CREATE_SPECIAL_COMPANY_FAIL";

////////////////////// Attached group ///////////////////////////////
export const GET_ATTACHED_GROUP_START = "GET_ATTACHED_GROUP_START";
export const GET_ATTACHED_GROUP_SUCCESS = "GET_ATTACHED_GROUP_SUCCESS";
export const GET_ATTACHED_GROUP_FAIL = "GET_ATTACHED_GROUP_FAIL";

////////////////////// Category ///////////////////////////////
export const GET_CATEGORY_START = "GET_CATEGORY_START";
export const GET_CATEGORY_SUCCESS = "GET_CATEGORY_SUCCESS";
export const GET_CATEGORY_FAIL = "GET_CATEGORY_FAIL";

export const DELETE_CATEGORY_START = "DELETE_CATEGORY_START";
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";
export const DELETE_CATEGORY_FAIL = "DELETE_CATEGORY_FAIL";

export const CREATE_CATEGORY_START = "CREATE_CATEGORY_START";
export const CREATE_CATEGORY_SUCCESS = "CREATE_CATEGORY_SUCCESS";
export const CREATE_CATEGORY_FAIL = "CREATE_CATEGORY_FAIL";

export const UPDATE_CATEGORY_START = "UPDATE_CATEGORY_START";
export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS";
export const UPDATE_CATEGORY_FAIL = "UPDATE_CATEGORY_FAIL";

////////////////////// Offers ///////////////////////////////
export const GET_OFFER_START = "GET_OFFER_START";
export const GET_OFFER_SUCCESS = "GET_OFFER_SUCCESS";
export const GET_OFFER_FAIL = "GET_OFFER_FAIL";

export const GET_OFFER_BY_ID_START = "GET_OFFER_BY_ID_START";
export const GET_OFFER_BY_ID_SUCCESS = "GET_OFFER_BY_ID_SUCCESS";
export const GET_OFFER_BY_ID_FAIL = "GET_OFFER_BY_ID_FAIL";

export const GET_MORE_OFFER_START = "GET_MORE_OFFER_START";
export const GET_MORE_OFFER_SUCCESS = "GET_MORE_OFFER_SUCCESS";
export const GET_MORE_OFFER_FAIL = "GET_MORE_OFFER_FAIL";

export const GET_TOP_OFFER_START = "GET_TOP_OFFER_START";
export const GET_TOP_OFFER_SUCCESS = "GET_TOP_OFFER_SUCCESS";
export const GET_TOP_OFFER_FAIL = "GET_TOP_OFFER_FAIL";

export const GET_OFFER_BY_COMPANY_START = "GET_OFFER_BY_COMPANY_START";
export const GET_OFFER_BY_COMPANY_SUCCESS = "GET_OFFER_BY_COMPANY_SUCCESS";
export const GET_OFFER_BY_COMPANY_FAIL = "GET_OFFER_BY_COMPANY_FAIL";

export const GET_SPECAIL_OFFER_BY_COMPANY_START = "GET_SPECAIL_OFFER_BY_COMPANY_START";
export const GET_SPECAIL_OFFER_BY_COMPANY_SUCCESS = "GET_SPECAIL_OFFER_BY_COMPANY_SUCCESS";
export const GET_SPECAIL_OFFER_BY_COMPANY_FAIL = "GET_SPECAIL_OFFER_BY_COMPANY_FAIL";

export const GET_OFFER_REPORT_START = "GET_OFFER_REPORT_START";
export const GET_OFFER_REPORT_SUCCESS = "GET_OFFER_REPORT_SUCCESS";
export const GET_OFFER_REPORT_FAIL = "GET_OFFER_REPORT_FAIL";

export const DELETE_OFFER_START = "DELETE_OFFER_START";
export const DELETE_OFFER_SUCCESS = "DELETE_OFFER_SUCCESS";
export const DELETE_OFFER_FAIL = "DELETE_OFFER_FAIL";

export const CREATE_OFFER_START = "CREATE_OFFER_START";
export const CREATE_OFFER_SUCCESS = "CREATE_OFFER_SUCCESS";
export const CREATE_OFFER_FAIL = "CREATE_OFFER_FAIL";

export const UPDATE_OFFER_START = "UPDATE_OFFER_START";
export const UPDATE_OFFER_SUCCESS = "UPDATE_OFFER_SUCCESS";
export const UPDATE_OFFER_FAIL = "UPDATE_OFFER_FAIL";

export const CHANGE_OFFER_STATUS_START = "CHANGE_OFFER_STATUS_START";
export const CHANGE_OFFER_STATUS_SUCCESS = "CHANGE_OFFER_STATUS_SUCCESS";
export const CHANGE_OFFER_STATUS_FAIL = "CHANGE_OFFER_STATUS_FAIL";

export const CHANGE_OFFER_STATUS_CU_START = "CHANGE_OFFER_STATUS_CU_START";
export const CHANGE_OFFER_STATUS_CU_SUCCESS = "CHANGE_OFFER_STATUS_CU_SUCCESS";
export const CHANGE_OFFER_STATUS_CU_FAIL = "CHANGE_OFFER_STATUS_CU_FAIL";

export const CHANGE_OFFER_STATUS_ARCHIVE_START = "CHANGE_OFFER_STATUS_ARCHIVE_START";
export const CHANGE_OFFER_STATUS_ARCHIVE_SUCCESS = "CHANGE_OFFER_STATUS_ARCHIVE_SUCCESS";
export const CHANGE_OFFER_STATUS_ARCHIVE_FAIL = "CHANGE_OFFER_STATUS_ARCHIVE_FAIL";

////////////////////// Dashboard ///////////////////////////////

export const GET_DASHBOARD_REPORT_START = "GET_DASHBOARD_REPORT_START";
export const GET_DASHBOARD_REPORT_SUCCESS = "GET_DASHBOARD_REPORT_SUCCESS";
export const GET_DASHBOARD_REPORT_FAIL = "GET_DASHBOARD_REPORT_FAIL";

////////////////////// Region ///////////////////////////////
export const GET_REGION_NAME_START = "GET_REGION_NAME_START";
export const GET_REGION_NAME_SUCCESS = "GET_REGION_NAME_SUCCESS";
export const GET_REGION_NAME_FAIL = "GET_REGION_NAME_FAIL";

////////////////////// PERMISSION ///////////////////////////////
export const GET_PERMISSION_START = "GET_PERMISSION_START";
export const GET_PERMISSION_SUCCESS = "GET_PERMISSION_SUCCESS";
export const GET_PERMISSION_FAIL = "GET_PERMISSION_FAIL";

export const DELETE_PERMISSION_START = "DELETE_PERMISSION_START";
export const DELETE_PERMISSION_SUCCESS = "DELETE_PERMISSION_SUCCESS";
export const DELETE_PERMISSION_FAIL = "DELETE_PERMISSION_FAIL";

export const CREATE_PERMISSION_START = "CREATE_PERMISSION_START";
export const CREATE_PERMISSION_SUCCESS = "CREATE_PERMISSION_SUCCESS";
export const CREATE_PERMISSION_FAIL = "CREATE_PERMISSION_FAIL";

export const UPDATE_PERMISSION_START = "UPDATE_PERMISSION_START";
export const UPDATE_PERMISSION_SUCCESS = "UPDATE_PERMISSION_SUCCESS";
export const UPDATE_PERMISSION_FAIL = "UPDATE_PERMISSION_FAIL";

////////////////////// ROLE ///////////////////////////////
export const GET_ROLE_START = "GET_ROLE_START";
export const GET_ROLE_SUCCESS = "GET_ROLE_SUCCESS";
export const GET_ROLE_FAIL = "GET_ROLE_FAIL";

export const DELETE_ROLE_START = "DELETE_ROLE_START";
export const DELETE_ROLE_SUCCESS = "DELETE_ROLE_SUCCESS";
export const DELETE_ROLE_FAIL = "DELETE_ROLE_FAIL";

export const CREATE_ROLE_START = "CREATE_ROLE_START";
export const CREATE_ROLE_SUCCESS = "CREATE_ROLE_SUCCESS";
export const CREATE_ROLE_FAIL = "CREATE_ROLE_FAIL";

export const UPDATE_ROLE_START = "UPDATE_ROLE_START";
export const UPDATE_ROLE_SUCCESS = "UPDATE_ROLE_SUCCESS";
export const UPDATE_ROLE_FAIL = "UPDATE_ROLE_FAIL";

////////////////////// USER ROLE ///////////////////////////////
export const GET_USER_ROLE_START = "GET_USER_ROLE_START";
export const GET_USER_ROLE_SUCCESS = "GET_USER_ROLE_SUCCESS";
export const GET_USER_ROLE_FAIL = "GET_USER_ROLE_FAIL";

export const DELETE_USER_ROLE_START = "DELETE_USER_ROLE_START";
export const DELETE_USER_ROLE_SUCCESS = "DELETE_USER_ROLE_SUCCESS";
export const DELETE_USER_ROLE_FAIL = "DELETE_USER_ROLE_FAIL";

export const CREATE_USER_ROLE_START = "CREATE_USER_ROLE_START";
export const CREATE_USER_ROLE_SUCCESS = "CREATE_USER_ROLE_SUCCESS";
export const CREATE_USER_ROLE_FAIL = "CREATE_USER_ROLE_FAIL";

export const UPDATE_USER_ROLE_START = "UPDATE_USER_ROLE_START";
export const UPDATE_USER_ROLE_SUCCESS = "UPDATE_USER_ROLE_SUCCESS";
export const UPDATE_USER_ROLE_FAIL = "UPDATE_USER_ROLE_FAIL";

////////////////////// Log ///////////////////////////////
export const GET_LOG_START = "GET_LOG_START";
export const GET_LOG_SUCCESS = "GET_LOG_SUCCESS";
export const GET_LOG_FAIL = "GET_LOG_FAIL";

export const GET_ACTIVITY_LOGIN_START = "GET_ACTIVITY_LOGIN_START";
export const GET_ACTIVITY_LOGIN_SUCCESS = "GET_ACTIVITY_LOGIN_SUCCESS";
export const GET_ACTIVITY_LOGIN_FAIL = "GET_ACTIVITY_LOGIN_FAIL";

export const GET_MORE_ACTIVITY_LOGIN_START = "GET_MORE_ACTIVITY_LOGIN_START";
export const GET_MORE_ACTIVITY_LOGIN_SUCCESS = "GET_MORE_ACTIVITY_LOGIN_SUCCESS";
export const GET_MORE_ACTIVITY_LOGIN_FAIL = "GET_MORE_ACTIVITY_LOGIN_FAIL";

////////////////////// Notification ///////////////////////////////
export const GET_NOTIFICATION_START = "GET_NOTIFICATION_START";
export const GET_NOTIFICATION_SUCCESS = "GET_NOTIFICATION_SUCCESS";
export const GET_NOTIFICATION_FAIL = "GET_NOTIFICATION_FAIL";

export const UPDATE_NOTIFICATION_START = "UPDATE_NOTIFICATION_START";
export const UPDATE_NOTIFICATION_SUCCESS = "UPDATE_NOTIFICATION_SUCCESS";
export const UPDATE_NOTIFICATION_FAIL = "UPDATE_NOTIFICATION_FAIL";

////////////////////// Report ///////////////////////////////
export const GET_REPORT_SUCCESS = "GET_REPORT_SUCCESS";
export const GET_REPORT_START = "GET_REPORT_START";
export const GET_REPORT_FAIL = "GET_REPORT_FAIL";
