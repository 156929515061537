import * as actionTypes from "../actionTypes";

const initialState = {
    loading: false,
    loadingPhone: false,
    authUser: [],
    msg: "",
    msgReset: "",
    smsPhone: "",
    msgFail: "",
    notification: {
        status: false,
        type: "",
        message: "",
    },
};

export default function userReducer(state = initialState, action = {}) {
    switch (action.type) {
        case actionTypes.NOTIFICATION_STATUS_FALSE:
            return {
                ...state,
                notification: { status: false, type: "", message: "" },
            };

        case actionTypes.LOGIN_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.LOGIN_SUCCESS:
            return {
                ...state,
                authUser: action.data.user,
                loading: false,
                notification: { status: true, type: "success", message: "success" },
            };
        case actionTypes.LOGIN_FAIL:
            return {
                ...state,
                loading: false,
                notification: { status: true, type: "error", message: action.data },
            };

        case actionTypes.LOGIN_PHONE_START:
            return {
                ...state,
                loadingPhone: true,
            };
        case actionTypes.LOGIN_PHONE_SUCCESS:
            return {
                ...state,
                msg: action.data.msg,
                smsPhone: action.data.phone,
                loadingPhone: false,
                notification: { status: true, type: "success", message: "SMS sent" },
            };
        case actionTypes.LOGIN_PHONE_FAIL:
            return {
                ...state,
                loadingPhone: false,
                msgFail: action.data,
                notification: { status: true, type: "error", message: action.data },
            };

        case actionTypes.LOGOUT_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.LOGOUT_SUCCESS:
            localStorage.clear();
            return {
                ...state,
                authUser: action.data.user,
                loading: false,
                notification: { status: true, type: "success", message: "success" },
            };
        case actionTypes.LOGOUT_FAIL:
            localStorage.clear();
            return {
                ...state,
                loading: false,
                notification: { status: true, type: "error", message: action.data },
            };

        case actionTypes.RESET_START:
            return {
                ...state,
            };
        case actionTypes.RESET_SUCCESS:
            return {
                ...state,
                msgReset: action.data.msg,
                notification: {
                    status: true,
                    type: "success",
                    message: "Link to reset your password has been sent to that email!",
                },
            };
        case actionTypes.RESET_FAIL:
            return {
                ...state,
                loadingPhone: false,
                notification: { status: true, type: "error", message: action.data },
            };

        case actionTypes.SET_VCODE:
            return {
                ...state,
                vcode: action.data,
            };

        default:
            return state;
    }
}
