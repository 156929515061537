import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createUseStyles } from "react-jss";
import { withTranslation } from "react-i18next";
import { Permissions } from "../../../config";

import {
    PieChart,
    Pie,
    Sector,
    Cell,
    ResponsiveContainer,
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
} from "recharts";
import {
    Group,
    ListAlt,
    FiberManualRecord,
    LibraryAddCheck,
    Business,
    Language,
} from "@mui/icons-material";
import { MenuItem, Select, ListSubheader } from "@mui/material";

// core components
import Card from "../../../components/Card/Card.js";
import CardBody from "../../../components/Card/CardBody.js";
import CardHeader from "../../../components/Card/CardHeader.js";
import CardIcon from "../../../components/Card/CardIcon.js";
import CardFooter from "../../../components/Card/CardFooter.js";
import Button from "../../../components/CustomButtons/Button.js";

import GoogleMapNew from "../../../components/GoogleMap/googleMapNew";
import styles from "../../../assets/jss/material-dashboard-pro-react/views/chartsStyle.js";
import { getSpecialCompanyByUserId } from "../../../store/actions/specialCompany";
import {
    getDashboardForCompanyUser,
    getDashboardForGroupUser,
} from "../../../store/actions/dashboard";
import { LoaderSpinner } from "../../../components/Loader";
import formatWithThousandSeparator from "utils/thousand_separator";

const useStyles = createUseStyles(styles);

const Dashboard = ({ t }) => {
    const classes = useStyles();
    const { dashboard, loading } = useSelector((state) => state.dashboard);
    const [acFaStatus, setAcFaStatus] = React.useState("week");
    const dispatch = useDispatch();
    const RADIAN = Math.PI / 180;
    const store = JSON.parse(localStorage.getItem("user"));
    const companies =
        store && store.companies.filter((item) => item.id !== 1305 || item.id === null);
    const [companyUserId, setCompanyUserId] = useState(store.user.torgid_company_id);
    const [groupId, setGroupId] = useState(store.groupIds[0]);
    const [companyGroupId, setCompanyGroupId] = useState(store.user.torgid_company_id);
    const [companyGroupType, setCompanyGroupType] = useState(false);
    const { specialCompanies, loading: loadingSpecialCompanies } = useSelector(
        (state) => state.specialCompanies
    );
    const userPermission = store && store.permission;
    const viewMyEmployees = userPermission.includes(Permissions.CU_EMPLOYEE_VIEW);
    let userIsGroupAdmin = store && store.roles.length === 1 && store.roles[0] === "group_admin";
    let userHasTwoRoles =
        store &&
        store.roles.length > 1 &&
        store.roles.includes("group_admin") &&
        store.roles.includes("company_user");

    useEffect(() => {
        if (userIsGroupAdmin) {
            dispatch(getSpecialCompanyByUserId(store.user.torgid_user_id));
            dispatch(getDashboardForGroupUser(groupId));
        } else if (userHasTwoRoles) {
            dispatch(getSpecialCompanyByUserId(store.user.torgid_user_id));
            dispatch(getDashboardForCompanyUser(companyGroupId));
        } else {
            dispatch(getDashboardForCompanyUser(companyUserId));
        }
    }, [dispatch]);

    if (loading || (userIsGroupAdmin && loadingSpecialCompanies)) return <LoaderSpinner />;
    const offerActivateAndFav = dashboard.results
        ? acFaStatus === "week"
            ? dashboard.results.activates_and_favourites_by_week
            : dashboard.results.activates_and_favourites_by_year
        : [{ name: "", activates: 0, favourites: 0 }];
    // const activateAndFavouriteData = dashboard.results ? dashboard.results.activates_and_favourites : [{name: '', activates: 0, favourites: 0}];
    const agesData =
        dashboard.results && dashboard.results.ages
            ? Object.keys(dashboard.results.ages).map(function (key) {
                  return { name: key, value: dashboard.results.ages[key] };
              })
            : [{ name: "", value: 0 }];
    const genderData = dashboard.results
        ? dashboard.results.genders.filter((item) => item.name !== "other")
        : [
              { name: "Male", value: 0 },
              { name: "Female", value: 0 },
          ];

    const COLORS = [
        "#BB001D",
        "#008353",
        "#D09600",
        "#FF8042",
        "#023e8a",
        "#16B4F3",
        "#DB18EC",
        "#F2CB04",
    ];
    const data =
        dashboard.results && dashboard.results.region_of_users
            ? dashboard.results.region_of_users
            : [
                  { name: "Reykjanes", value: 0 },
                  { name: "Suðurland", value: 0 },
                  { name: "Höfuðborgarsvæðið", value: 0 },
              ];
    const style = {
        bottom: "0",
        left: 0,
        lineHeight: "24px",
    };
    // const offerClicksTypes = {
    //   company: t('dash.company'),
    //   email: t('dash.email'),
    //   offer: t('dash.offer'),
    //   offer_link: t('dash.offer_link'),
    //   phone: t('dash.phone'),
    //   shared: t('dash.shared'),
    //   website: t('dash.website'),
    // };

    const renderCustomizedLabel = ({
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        percent,
        index,
    }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.4;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
        return (
            <text
                x={x}
                y={y}
                fill="white"
                textAnchor={x > cx ? "start" : "end"}
                dominantBaseline="central"
            >
                {`${(percent * 100).toFixed(1)}%`}
            </text>
        );
    };

    // const tableDataOfferClicks = !dashboard.results ? [[]] : Object.entries(dashboard.results.offer_clicks[0]).map(([k,v]) => {
    //   if(k === 'torgid_offer') return [];
    //     return ([
    //       <span style={{textTransform: 'capitalize'}}>{offerClicksTypes[k]}</span>,
    //       v ? v : 0
    //     ]);
    // });

    const handleChange = (data) => {
        setCompanyUserId(data);
        dispatch(getDashboardForCompanyUser(data));
    };

    const handleChangeGroup = (data) => {
        setGroupId(data);
        dispatch(getDashboardForGroupUser(data));
    };

    const handleChangeCompanyGroup = (data, type) => {
        setCompanyGroupId(data);
        if (type === "group") {
            setCompanyGroupType(true);
            dispatch(getDashboardForGroupUser(data));
        } else {
            setCompanyGroupType(false);
            dispatch(getDashboardForCompanyUser(data));
        }
    };

    const xAxisTickFormatter = (date) => {
        return t(date);
    };
    console.log("offerActivateAndFav", offerActivateAndFav);
    return (
        <div>
            <div
                style={{
                    display: "grid",
                    gap: 16,
                    gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
                    marginBottom: 16,
                }}
            >
                {userHasTwoRoles && companies && specialCompanies && (
                    <Card style={{ margin: 0 }}>
                        <CardHeader>
                            <CardIcon color="rose">
                                <Business />
                            </CardIcon>
                            <p className={classes.cardCategory2}>
                                {companyGroupType ? t("menu.groups") : t("companies")}
                            </p>
                            <Select
                                MenuProps={{ className: classes.selectMenu }}
                                classes={{ select: classes.select }}
                                value={companyGroupId}
                                onChange={(e, s) =>
                                    handleChangeCompanyGroup(e.target.value, s.props.type)
                                }
                                inputProps={{ name: "discount", id: "discount" }}
                                style={{ maxWidth: "79%", width: "79%" }}
                            >
                                <ListSubheader>{t("menu.companies")}</ListSubheader>
                                {companies &&
                                    companies.map((value, index) => (
                                        <MenuItem key={index} value={value.id} type={"company"}>
                                            {value.name}
                                        </MenuItem>
                                    ))}

                                <ListSubheader>{t("menu.groups")}</ListSubheader>
                                {specialCompanies.results &&
                                    specialCompanies.results.map((value, index) => (
                                        <MenuItem key={index} value={value.id} type={"group"}>
                                            {value.name}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </CardHeader>
                    </Card>
                )}
                {!userHasTwoRoles && companies.length > 1 && (
                    <Card style={{ margin: 0 }}>
                        <CardHeader>
                            <CardIcon color="rose">
                                <Business />
                            </CardIcon>
                            <p className={classes.cardCategory2}>{t("companies")}</p>
                            <Select
                                MenuProps={{ className: classes.selectMenu }}
                                classes={{ select: classes.select }}
                                value={companyUserId}
                                onChange={(e) => handleChange(e.target.value)}
                                inputProps={{ name: "discount", id: "discount" }}
                                style={{ maxWidth: "79%", width: "79%" }}
                            >
                                {companies &&
                                    companies.map((value, index) => (
                                        <MenuItem key={index} value={value.id}>
                                            {value.name}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </CardHeader>
                    </Card>
                )}
                {userIsGroupAdmin && specialCompanies && specialCompanies?.results?.length > 1 && (
                    <Card style={{ margin: 0 }}>
                        <CardHeader>
                            <CardIcon color="rose">
                                <Business />
                            </CardIcon>
                            <p className={classes.cardCategory2}>{t("special_companies")}</p>
                            <Select
                                MenuProps={{ className: classes.selectMenu }}
                                classes={{ select: classes.select }}
                                value={groupId}
                                onChange={(e) => handleChangeGroup(e.target.value)}
                                inputProps={{ name: "discount", id: "discount" }}
                                style={{ maxWidth: "79%", width: "79%" }}
                            >
                                {specialCompanies.results &&
                                    specialCompanies.results.map((value, index) => (
                                        <MenuItem key={index} value={value.id}>
                                            {value.name}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </CardHeader>
                    </Card>
                )}
                {(userIsGroupAdmin || companyGroupType) && (
                    <Card style={{ margin: 0 }}>
                        <CardHeader>
                            <CardIcon color="rose">
                                <Group />
                            </CardIcon>
                            <p className={classes.cardCategory2}> {t("menu.members")} </p>
                            <h3 className={classes.cardTitle}>
                                {dashboard.results && dashboard.results.members}
                            </h3>
                        </CardHeader>
                    </Card>
                )}

                <Card style={{ margin: 0 }}>
                    <CardHeader>
                        <CardIcon color="rose">
                            <ListAlt />
                        </CardIcon>
                        <p className={classes.cardCategory2}>{t("dash.cu_offers")}</p>
                        <h3 className={classes.cardTitle}>
                            {dashboard.results && dashboard.results.offer_limit
                                ? dashboard?.results?.offers +
                                  " " +
                                  t("of") +
                                  " " +
                                  dashboard.results.offer_limit
                                : dashboard?.results?.offers}
                        </h3>
                    </CardHeader>
                </Card>
            </div>
            <div
                style={{
                    display: "grid",
                    gap: 16,
                    gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                    marginBottom: 16,
                }}
            >
                <Card style={{ margin: 0 }}>
                    <CardHeader>
                        <CardIcon color="rose">
                            <LibraryAddCheck />
                        </CardIcon>
                        <h4 className={classes.cardCategory2}>{t("dash.offers_activity")}</h4>
                        <Button
                            className={classes.actionButton}
                            color={acFaStatus === "week" ? "info" : "default"}
                            size="sm"
                            onClick={() => setAcFaStatus("week")}
                        >
                            {t("month")}
                        </Button>
                        <Button
                            className={classes.actionButton}
                            color={acFaStatus === "year" ? "info" : "default"}
                            size="sm"
                            onClick={() => setAcFaStatus("year")}
                        >
                            {t("year")}
                        </Button>
                    </CardHeader>
                    <CardBody>
                        <ResponsiveContainer width="100%" height={400}>
                            <BarChart
                                width="100%"
                                height="100%"
                                data={offerActivateAndFav}
                                margin={{ top: 5, right: 10, left: 5, bottom: 0 }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                {acFaStatus === "week" ? (
                                    <XAxis dataKey="date" />
                                ) : (
                                    <XAxis dataKey="month" tickFormatter={xAxisTickFormatter} />
                                )}
                                <YAxis />
                                {acFaStatus === "week" ? (
                                    <Tooltip />
                                ) : (
                                    <Tooltip labelFormatter={xAxisTickFormatter} />
                                )}
                                <Bar
                                    dataKey="opens"
                                    name={t("dash.opens")}
                                    fill="#16B4F3"
                                    stackId="a" // Add stackId
                                />
                                <Bar
                                    dataKey="activates"
                                    name={t("dash.activates")}
                                    fill="#008B6B"
                                    stackId="a" // Add stackId
                                />
                                <Bar
                                    dataKey="favourites"
                                    name={t("dash.favourites")}
                                    fill="#DE2C48"
                                    stackId="a" // Add stackId
                                />
                            </BarChart>
                        </ResponsiveContainer>
                    </CardBody>
                    <CardFooter stats className={classes.cardFooter} style={{ marginTop: "5px" }}>
                        <FiberManualRecord style={{ color: "#16B4F3" }} /> {t("dash.opens")}
                        {` `} (
                        {dashboard.results
                            ? acFaStatus === "week"
                                ? formatWithThousandSeparator(
                                      dashboard.results.offer_opened_by_week_count
                                  )
                                : formatWithThousandSeparator(
                                      dashboard.results.offer_opened_by_year_count
                                  )
                            : 0}
                        )
                        <FiberManualRecord style={{ color: "#008B6B" }} /> {t("dash.activates")}
                        {` `} (
                        {dashboard.results
                            ? acFaStatus === "week"
                                ? formatWithThousandSeparator(
                                      dashboard.results.offer_activates_by_week_count
                                  )
                                : formatWithThousandSeparator(
                                      dashboard.results.offer_activates_by_year_count
                                  )
                            : 0}
                        )
                        <FiberManualRecord style={{ color: "#DE2C48" }} /> {t("dash.favourites")}
                        {` `} (
                        {dashboard.results
                            ? acFaStatus === "week"
                                ? formatWithThousandSeparator(
                                      dashboard.results.user_favourites_by_week_count
                                  )
                                : formatWithThousandSeparator(
                                      dashboard.results.user_favourites_by_year_count
                                  )
                            : 0}
                        )
                    </CardFooter>
                </Card>
                <Card style={{ margin: 0 }}>
                    <CardHeader>
                        <CardIcon color="rose">
                            <Language />
                        </CardIcon>
                        <h4 className={classes.cardCategory}> {t("dash.number_offers")} </h4>
                    </CardHeader>
                    <CardBody style={{ minHeight: 400 }}>
                        {dashboard.results && dashboard.results.offer_locations && (
                            <GoogleMapNew companies={dashboard.results.offer_locations} />
                        )}
                    </CardBody>
                </Card>
            </div>
            <div
                style={{
                    display: "grid",
                    gap: 16,
                    gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                    marginBottom: 16,
                }}
            >
                <Card style={{ margin: 0 }}>
                    <CardHeader>
                        <CardIcon color="rose">
                            <Group />
                        </CardIcon>
                        <h4 className={classes.cardCategory}>{t("dash.gender_customers")}</h4>
                    </CardHeader>
                    <CardBody>
                        <ResponsiveContainer width="100%" height={200}>
                            <PieChart width={200} height={200}>
                                <Pie
                                    data={genderData}
                                    cx="50%"
                                    cy="50%"
                                    labelLine={false}
                                    label={renderCustomizedLabel}
                                    outerRadius={100}
                                    fill="#8884d8"
                                    dataKey="value"
                                >
                                    {genderData.map((entry, index) => (
                                        <Cell
                                            key={`cell-${index}`}
                                            fill={
                                                (entry.name === "male" && "#007AAA") ||
                                                (entry.name === "female" && "#DB18EC") ||
                                                (entry.name === "other" && "#F2CB04")
                                            }
                                        />
                                    ))}
                                </Pie>
                            </PieChart>
                        </ResponsiveContainer>
                    </CardBody>
                    <CardFooter stats className={classes.cardFooter}>
                        <FiberManualRecord
                            className={classes.primary}
                            style={{ color: "#007AAA" }}
                        />
                        {t("male")}
                        {` `}
                        <FiberManualRecord
                            className={classes.warning}
                            style={{ color: "#DB18EC" }}
                        />
                        {t("female")}
                        {` `}
                        {/*<FiberManualRecord className={classes.warning}  />{t('other')}{` `}*/}
                    </CardFooter>
                </Card>

                <Card style={{ margin: 0 }}>
                    <CardHeader>
                        <CardIcon color="rose">
                            <Group />
                        </CardIcon>
                        <h4 className={classes.cardCategory}>{t("dash.age_customers")}</h4>
                    </CardHeader>
                    <CardBody>
                        <ResponsiveContainer width="100%" height={260}>
                            <BarChart
                                width={500}
                                height={200}
                                data={agesData}
                                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Bar dataKey="value" name={t("users")} fill="#16B4F3" />
                            </BarChart>
                        </ResponsiveContainer>
                    </CardBody>
                </Card>

                <Card style={{ margin: 0 }}>
                    <CardHeader>
                        <CardIcon color="rose">
                            <Group />
                        </CardIcon>
                        <h4 className={classes.cardCategory}>{t("dash.region_customers")}</h4>
                    </CardHeader>
                    <CardBody>
                        <ResponsiveContainer width="100%" height={260}>
                            <PieChart width={400} height={400}>
                                <Pie
                                    data={data}
                                    cx="50%"
                                    cy="50%"
                                    labelLine={true}
                                    // label={renderCustomizedLabel}
                                    outerRadius={70}
                                    fill="#8884d8"
                                    dataKey="value"
                                    label
                                >
                                    {data.map((entry, index) => (
                                        <Cell
                                            key={`cell-${index}`}
                                            fill={COLORS[index % COLORS.length]}
                                        />
                                    ))}
                                </Pie>
                                <Tooltip />
                                <Legend
                                    iconSize={10}
                                    layout="horizontal"
                                    verticalAlign="bottom"
                                    wrapperStyle={style}
                                />
                            </PieChart>
                        </ResponsiveContainer>
                    </CardBody>
                </Card>
            </div>
        </div>
    );
};

export default withTranslation("", { withRef: true })(Dashboard);
