import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Permissions } from "../../../config";

// material-ui
import { createUseStyles } from "react-jss";
import { Assignment, Edit, AddAlert } from "@mui/icons-material";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import SnackbarCustom from "components/Snackbar/Snackbar.js";
import DeleteButton from "components/Main/DeleteButton.js";

import stylesAlert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

import { deleteCompanyUser, getCompanyUsersByCompanies } from "../../../store/actions/companyUser";
import CompanyUsersTable from "./CompanyUsersTable";
import { LoaderSpinner } from "../../../components/Loader";

const style = {
    ...styles,
    ...stylesAlert,
};

const useStyles = createUseStyles(style);

const CompanyUser = ({ t }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { companyUsers, loading } = useSelector((state) => state.companyUser);
    const { status, message, type } = useSelector((state) => state.companyUser.notification);
    const [alert, setAlert] = React.useState(null);
    const userPermission = JSON.parse(localStorage.getItem("user")).permission;
    const companyIds = JSON.parse(localStorage.getItem("user")).companyIds;

    useEffect(() => {
        // Filter null values from companyIds:
        let ids = companyIds && companyIds.filter((id) => id !== null);

        dispatch(getCompanyUsersByCompanies(ids));
    }, [dispatch]);

    const updateButton = (user) => {
        return (
            <Button
                color="success"
                className={classes.actionButton}
                title={t("edit")}
                key={user.id + "update"}
                onClick={() => navigate("/add-new-company-user", { state: { user: user } })}
            >
                <Edit className={classes.icon} />
            </Button>
        );
    };

    const deleteButton = (data) => {
        return (
            <DeleteButton
                key={data.id + "_delete"}
                style={{ margin: 0 }}
                action={deleteCompanyUser({ ...data })}
                setLimit={() => {}}
            />
        );
    };

    if (loading) return <LoaderSpinner />;

    return (
        <GridContainer>
            {alert}
            <SnackbarCustom
                place="br"
                icon={AddAlert}
                color={type === "success" ? "success" : "warning"}
                message={message}
                open={status}
            />
            <GridItem xs={12}>
                <Card>
                    <CardHeader color="info" icon>
                        <GridContainer justifyContent="space-between" container>
                            <GridItem md={3}>
                                <CardHeader>
                                    <CardIcon color="rose">
                                        {" "}
                                        <Assignment />{" "}
                                    </CardIcon>
                                    <span className={classes.tableTitle}>
                                        {t("menu.company_users")}{" "}
                                    </span>
                                </CardHeader>
                            </GridItem>
                            <GridItem>
                                {userPermission.includes(Permissions.CU_COMPANY_USER_CREATE) && (
                                    <Link to="/add-new-company-user">
                                        <Button color="rose" className={classes.addButton}>
                                            {" "}
                                            {t("company_user.add_new_user")}{" "}
                                        </Button>
                                    </Link>
                                )}
                            </GridItem>
                        </GridContainer>
                    </CardHeader>
                    <CardBody>
                        <CompanyUsersTable
                            data={companyUsers}
                            updateButton={updateButton}
                            deleteButton={deleteButton}
                        />
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    );
};

export default withTranslation("", { withRef: true })(CompanyUser);
