import * as actionTypes from "../actionTypes";
import axios from "../middlewares/axios";

export const changeNotificationStatus = () => async (dispatch) => {
    dispatch({ type: actionTypes.NOTIFICATION_STATUS_FALSE });
};

export const getCompanies = (page, loadMore) => async (dispatch) => {
    dispatch({
        type: loadMore ? actionTypes.GET_MORE_COMPANY_START : actionTypes.GET_COMPANY_START,
    });
    try {
        const res = await axios.get(page ? "/api/company?page=" + page : "/api/company");
        dispatch({
            type: loadMore ? actionTypes.GET_MORE_COMPANY_SUCCESS : actionTypes.GET_COMPANY_SUCCESS,
            data: res.data,
        });
    } catch (error) {
        dispatch({
            type: loadMore ? actionTypes.GET_MORE_COMPANY_FAIL : actionTypes.GET_COMPANY_FAIL,
        });
    }
};

export const searchCompanies = (search) => async (dispatch) => {
    dispatch({ type: actionTypes.GET_COMPANY_START });
    try {
        const res = await axios.get("/api/company/search/" + search);
        dispatch({ type: actionTypes.GET_COMPANY_SUCCESS, data: res.data });
    } catch (error) {
        dispatch({ type: actionTypes.GET_COMPANY_FAIL });
    }
};

export const getCompanyById = (id) => async (dispatch) => {
    dispatch({ type: actionTypes.GET_COMPANY_BY_ID_START });
    try {
        const res = await axios.get("/api/company/" + id);
        dispatch({ type: actionTypes.GET_COMPANY_BY_ID_SUCCESS, data: res.data });
    } catch (error) {
        dispatch({ type: actionTypes.GET_COMPANY_BY_ID_FAIL });
    }
};

export const getCompanyByIds = (id) => async (dispatch) => {
    dispatch({ type: actionTypes.GET_COMPANY_BY_ID_START });
    try {
        const res = await axios.get("/api/company/by_ids/" + id);
        dispatch({ type: actionTypes.GET_COMPANY_BY_ID_SUCCESS, data: res.data });
    } catch (error) {
        dispatch({ type: actionTypes.GET_COMPANY_BY_ID_FAIL });
    }
};

export const getCompanyByKennitala = (kennitala) => async (dispatch) => {
    dispatch({ type: actionTypes.GET_COMPANY_BY_ID_START });
    try {
        const res = await axios.get("/api/company/company_by_kennitala/" + kennitala);
        dispatch({ type: actionTypes.GET_COMPANY_BY_ID_SUCCESS, data: res.data });
    } catch (error) {
        dispatch({ type: actionTypes.GET_COMPANY_BY_ID_FAIL });
    }
};

export const createUpdateCompany = ({ update, ...company }) => async (dispatch) => {
    dispatch({
        type: update ? actionTypes.UPDATE_COMPANY_START : actionTypes.CREATE_COMPANY_START,
    });
    try {
        const res = await axios({
            url: `${update ? "/api/company" + `/${company.about.id}` : "/api/company"}`,
            method: update ? "PUT" : "POST",
            data: {
                torgid_company_locations: company.locations.locations,
                // "torgid_company_employees": company.employees.employees,
                ...company.about,
                // ...company.info
            },
        });
        dispatch({
            type: update ? actionTypes.UPDATE_COMPANY_SUCCESS : actionTypes.CREATE_COMPANY_SUCCESS,
            data: res.data,
        });
        window.location.href = "companies";
    } catch (error) {
        const res = error.response.data;
        const message = res.results ? res.results[Object.keys(res.results)[0]] : "";
        const errorMessage = res.message + ". " + message;
        dispatch({
            type: update ? actionTypes.UPDATE_COMPANY_FAIL : actionTypes.CREATE_COMPANY_FAIL,
            data: errorMessage,
        });
    }
};

export const deleteCompany = ({ ...data }) => async (dispatch) => {
    dispatch({ type: actionTypes.DELETE_COMPANY_START });
    try {
        const res = await axios.delete("/api/company/" + data.id);
        dispatch({ type: actionTypes.DELETE_COMPANY_SUCCESS, data: res.data });
    } catch (error) {
        dispatch({ type: actionTypes.DELETE_COMPANY_FAIL });
    }
};

export const changeCompanyStatus = (company, status) => async (dispatch) => {
    dispatch({ type: actionTypes.CHANGE_COMPANY_STATUS_START });
    try {
        const res = await axios({
            url: "/api/company/disable_enable_company",
            method: "POST",
            data: {
                company_id: company,
                status: status,
            },
        });
        dispatch({ type: actionTypes.CHANGE_COMPANY_STATUS_SUCCESS, data: res.data });
        window.location.reload();
    } catch (error) {
        const res = error.response.data;
        const message = res.results ? res.results[Object.keys(res.results)[0]] : "";
        const errorMessage = res.message + ". " + message;
        dispatch({ type: actionTypes.CHANGE_COMPANY_STATUS_FAIL, data: errorMessage });
    }
};
