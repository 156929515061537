import axios from "axios";
const base = process.env.REACT_APP_SPARA_API_URL || "https://api.spara.is";
console.log("base", base, process.env.REACT_APP_SPARA_API_URL);

const instance = axios.create({
    baseURL: base,
    headers: {
        "Content-Type": "application/json",
        "APP-KEY": "3f8a97e6-51ea-44d7-a9c9-87ae6040f7fb",
    },
});

instance.interceptors.request.use((request) => {
    let store = localStorage.user ? JSON.parse(localStorage.getItem("user")) : "";
    if (store) {
        request.headers.Authorization = "Bearer " + store.access_token;
    }
    return request;
});

instance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (!axios.isCancel(error)) {
            if (error?.response?.status === 401) {
                localStorage.clear();
                window.location = "/";
            } else {
                return Promise.reject(error);
            }
        }
    }
);

export default instance;
